import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { API_URL, UPLOADS_URL } from '../config';
import { FaShare, FaDownload, FaHeart} from 'react-icons/fa';

const UserAvatarView = () => {
  const { username } = useParams();
  const [avatar, setAvatar] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLiked, setIsLiked] = useState(false);

  // Puntos por rareza
  const rarityPoints = {
    'común': 10,
    'raro': 25,
    'épico': 50,
    'legendario': 100,
    'única': 200
  };

  const calculateTotalPoints = useCallback((layers) => {
    if (!layers) return 0;
    // Solo contar capas que existen y tienen rareza
    return layers
      .filter(layer => layer && layer.rarity && layer.category)
      .reduce((total, layer) => total + (rarityPoints[layer.rarity] || 0), 0);
  }, [rarityPoints]);

  const getRarityColor = (rarity) => {
    const colors = {
      común: 'bg-gray-500',
      raro: 'bg-blue-500',
      épico: 'bg-purple-500',
      legendario: 'bg-yellow-500',
      única: 'bg-red-500'
    };
    return colors[rarity] || 'bg-gray-500';
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const fetchAvatar = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const response = await fetch(`${API_URL}/avatars/${username}`, {
          signal: controller.signal,
          headers: {
            'Cache-Control': 'no-cache'
          }
        });
        
        if (!response.ok) {
          throw new Error('Error al cargar el avatar');
        }

        const data = await response.json();

        if (isMounted) {
          setAvatar(data);
          setError(null);
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          return;
        }
        if (isMounted) {
          console.error('Error al cargar avatar:', error);
          setError(error.message);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    if (username) {
      fetchAvatar();
    }

    // Cleanup function
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [username]); // Solo depender de username

  const shareAvatar = async () => {
    try {
      await navigator.share({
        title: `Avatar de ${avatar.twitchUsername}`,
        text: `¡Mira mi avatar personalizado!`,
        url: window.location.href
      });
    } catch (err) {
      console.error('Error compartiendo:', err);
    }
  };

  const downloadAvatar = () => {
    const link = document.createElement('a');
    link.href = `${UPLOADS_URL}/saved-avatars/${avatar.avatarImage}`;
    link.download = `avatar-${avatar.twitchUsername}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Añadir el cálculo de totalPoints con verificación
  const totalPoints = avatar?.layers ? calculateTotalPoints(avatar.layers) : 0;

  if (loading) {
    return (
      <>
        {/* Fondo */}
        <div 
          className="fixed inset-0 -z-20"
          style={{ 
            backgroundImage: `url("/img/fondo.jpg")`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'black',
            filter: 'brightness(0.7) blur(1px)'
          }}
        />
        <div className="min-h-screen flex items-center justify-center">
          <div className="text-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-purple-500 mx-auto"></div>
            <p className="mt-4 text-white text-xl">Cargando avatar...</p>
          </div>
        </div>
      </>
    );
  }

  if (error || !avatar) {
    return (
      <>
        {/* Fondo */}
        <div 
          className="fixed inset-0 -z-20"
          style={{ 
            backgroundImage: `url("/img/fondo.jpg")`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'black',
            filter: 'brightness(0.7) blur(1px)'
          }}
        />
        <div className="min-h-screen flex items-center justify-center">
          <div className="bg-black/75 backdrop-blur-sm p-8 rounded-xl shadow-2xl text-center max-w-md border border-purple-500/30">
            <h2 className="text-2xl font-bold text-white mb-4">{error ? 'Error' : 'Avatar no encontrado'}</h2>
            <p className="text-gray-300 mb-6">{error || `No se encontró un avatar para el usuario ${username}`}</p>
            <Link 
              to="/" 
              className="inline-block px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-all duration-200 transform hover:-translate-y-1"
            >
              Volver al inicio
            </Link>
          </div>
        </div>
      </>
    );
  }

  const mainContent = (
    <>
      {/* Fondo */}
      <div 
        className="fixed inset-0 -z-20"
        style={{ 
          backgroundImage: `url("/img/fondo.jpg")`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'black',
          filter: 'brightness(0.7) blur(1px)'
        }}
      />

      {/* Footer */}
      <footer 
        className="fixed bottom-0 left-0 right-0 h-[100px] z-[-10] pointer-events-none select-none"
        style={{
          background: 'url(/img/footer.png) no-repeat center center',
          backgroundSize: 'cover'
        }}
      />

      {/* Contenido principal */}
      <div className="min-h-screen relative z-10 flex items-center justify-center px-4 sm:px-6 lg:px-8 py-12">
        <div className="max-w-4xl w-full mx-auto bg-black/75 backdrop-blur-sm rounded-2xl shadow-2xl overflow-hidden">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-6">
            {/* Contenedor de imagen */}
            <div className="relative aspect-square bg-gradient-to-br from-black to-purple-900/30 rounded-xl overflow-hidden">
              <img
                src={`${UPLOADS_URL}/saved-avatars/${avatar.avatarImage}`}
                alt={`Avatar de ${avatar.twitchUsername}`}
                className="absolute inset-0 w-full h-full object-contain cursor-pointer hover:scale-105 transition-transform duration-300"
              />
            </div>

            {/* Info Container */}
            <div className="flex flex-col h-full">
              <div className="flex justify-between items-start mb-6">
                <h2 className="text-3xl font-bold bg-gradient-to-r from-purple-500 to-pink-500 bg-clip-text text-transparent">
                  {avatar.twitchUsername}
                </h2>
                <div className="flex gap-2">
                  <button
                    onClick={shareAvatar}
                    className="p-2 rounded-lg bg-purple-600 hover:bg-purple-700 text-white transition-all duration-200 transform hover:-translate-y-1"
                  >
                    <FaShare />
                  </button>
                  <button
                    onClick={downloadAvatar}
                    className="p-2 rounded-lg bg-purple-600 hover:bg-purple-700 text-white transition-all duration-200 transform hover:-translate-y-1"
                  >
                    <FaDownload />
                  </button>
                  <button
                    onClick={() => setIsLiked(!isLiked)}
                    className={`p-2 rounded-lg transition-all duration-200 transform hover:-translate-y-1 ${
                      isLiked ? 'bg-red-600 hover:bg-red-700' : 'bg-purple-600 hover:bg-purple-700'
                    } text-white`}
                  >
                    <FaHeart />
                  </button>
                </div>
              </div>

              {/* Stats */}
              <div className="grid grid-cols-2 gap-4 mb-6">
                <div className="bg-black/50 p-4 rounded-xl border border-purple-500/30">
                  <div className="text-gray-400 text-sm">Última actualización</div>
                  <div className="text-white font-medium">
                    {new Date(avatar.updatedAt).toLocaleDateString()}
                  </div>
                </div>
                {avatar.ranking && (
                  <div className="bg-black/50 p-4 rounded-xl border border-purple-500/30">
                    <div className="text-gray-400 text-sm">Ranking</div>
                    <div className="text-white font-medium">
                      #{avatar.ranking.position} de {avatar.ranking.total}
                    </div>
                  </div>
                )}
              </div>

              {/* Puntuación */}
              {totalPoints > 0 && (
                <div className="bg-black/50 p-4 rounded-xl border border-purple-500/30 mb-6">
                  <div className="flex justify-between items-center">
                    <h3 className="text-lg font-bold text-white">Puntuación Total</h3>
                    <div className="text-2xl font-bold text-purple-400">
                      {totalPoints} pts
                    </div>
                  </div>
                </div>
              )}

              {/* Items */}
              <div className="flex-grow overflow-hidden">
                <h3 className="text-lg font-bold text-white mb-3">Items Equipados</h3>
                <div className="space-y-2 overflow-y-auto pr-2 max-h-[200px] scrollbar-thin scrollbar-track-black/30 scrollbar-thumb-purple-500/50 hover:scrollbar-thumb-purple-500/80">
                  {avatar?.layers
                    ?.filter(layer => layer && layer.rarity && layer.category)
                    .map((layer, index) => (
                      <div 
                        key={index} 
                        className="flex justify-between items-center bg-black/50 p-3 rounded-xl border border-purple-500/30"
                      >
                        <div className="flex items-center gap-3">
                          <span className={`w-2 h-2 rounded-full ${getRarityColor(layer.rarity)}`}></span>
                          <div>
                            <div className="text-white font-medium">{layer.name}</div>
                            <div className="text-sm text-gray-400">{layer.category}</div>
                          </div>
                        </div>
                        <div className="text-purple-400 font-medium">{rarityPoints[layer.rarity]} pts</div>
                      </div>
                    ))}
                </div>
              </div>

              <Link
                to="/"
                className="mt-6 inline-flex justify-center px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-all duration-200 transform hover:-translate-y-1"
              >
                Volver al inicio
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return mainContent;
};

export default UserAvatarView;