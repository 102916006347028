import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const InfoModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      {/* Overlay con animación de fade */}
      <div 
        className="absolute inset-0 bg-black/80 backdrop-blur-sm animate-fadeIn"
        onClick={onClose}
      />
      
      {/* Modal con animación de fade y scale */}
      <div className="relative bg-black/90 w-full max-w-3xl max-h-[90vh] overflow-y-auto rounded-2xl shadow-2xl border border-purple-500/30 animate-modalOpen">
        {/* Header */}
        <div className="flex justify-between items-center p-6 border-b border-purple-500/30">
          <h2 className="text-2xl font-bold bg-gradient-to-r from-purple-500 to-pink-500 bg-clip-text text-transparent">
            Los Misrraelitas
          </h2>
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <FontAwesomeIcon icon={faTimes} className="h-5 w-5" />
          </button>
        </div>

        {/* Content */}
        <div className="p-6 space-y-6">
          <div className="space-y-4">
            <p className="text-gray-300 leading-relaxed">
              Los Misrraelitas son la comunidad que representa a los seguidores más fieles de MisrraVB. Cada miembro tiene la oportunidad de crear su propio avatar único que lo identifica dentro de la comunidad.
            </p>

            <div className="bg-purple-900/20 border border-purple-500/30 rounded-xl p-4">
              <h3 className="text-xl font-semibold text-purple-400 mb-2">Sistema de Avatares</h3>
              <p className="text-gray-300">
                Cada avatar está compuesto por diferentes capas y elementos que varían en rareza, desde común hasta única. La combinación de estas capas determina la puntuación total del avatar y su posición en el ranking.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="bg-gray-800/50 rounded-xl p-4">
                <h4 className="text-lg font-semibold text-white mb-2">Rareza de Elementos</h4>
                <ul className="space-y-2">
                  <li className="flex items-center">
                    <span className="w-3 h-3 rounded-full bg-gray-500 mr-2"></span>
                    <span className="text-gray-300">Común - 10 puntos</span>
                  </li>
                  <li className="flex items-center">
                    <span className="w-3 h-3 rounded-full bg-blue-500 mr-2"></span>
                    <span className="text-gray-300">Raro - 25 puntos</span>
                  </li>
                  <li className="flex items-center">
                    <span className="w-3 h-3 rounded-full bg-purple-500 mr-2"></span>
                    <span className="text-gray-300">Épico - 50 puntos</span>
                  </li>
                  <li className="flex items-center">
                    <span className="w-3 h-3 rounded-full bg-yellow-500 mr-2"></span>
                    <span className="text-gray-300">Legendario - 100 puntos</span>
                  </li>
                  <li className="flex items-center">
                    <span className="w-3 h-3 rounded-full bg-red-500 mr-2"></span>
                    <span className="text-gray-300">Única - 200 puntos</span>
                  </li>
                </ul>
              </div>

              <div className="bg-gray-800/50 rounded-xl p-4">
                <h4 className="text-lg font-semibold text-white mb-2">Beneficios</h4>
                <ul className="space-y-2 text-gray-300">
                  <li>• Identidad única en la comunidad</li>
                  <li>• Participación en eventos especiales</li>
                  <li>• Competencia en el ranking global</li>
                  <li>• Acceso a elementos exclusivos</li>
                  <li>• Reconocimiento en el Hall of Fame</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoModal; 