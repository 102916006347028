import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { API_URL, UPLOADS_URL } from '../config';

const RankingPage = () => {
  const [avatars, setAvatars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const calculateTotalPoints = useCallback((layers) => {
    const rarityPoints = {
      'común': 10,
      'raro': 25,
      'épico': 50,
      'legendario': 100,
      'única': 200
    };
    return layers.reduce((total, layer) => total + (rarityPoints[layer.rarity] || 0), 0);
  }, []);

  useEffect(() => {
    const fetchAvatars = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_URL}/avatars/ranking`);
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || 'Error al cargar el ranking');
        }

        if (!Array.isArray(data)) {
          console.error('Datos recibidos no son un array:', data);
          setAvatars([]);
          return;
        }

        const avatarsWithPoints = data
          .filter(avatar => avatar && avatar.layers)
          .map(avatar => ({
            ...avatar,
            totalPoints: calculateTotalPoints(avatar.layers)
          }))
          .sort((a, b) => b.totalPoints - a.totalPoints);

        setAvatars(avatarsWithPoints);
      } catch (error) {
        console.error('Error detallado al cargar el ranking:', error);
        setError('Error al cargar el ranking');
      } finally {
        setLoading(false);
      }
    };

    fetchAvatars();
  }, [calculateTotalPoints]);

  if (loading) {
    return (
      <>
        {/* Fondo */}
        <div 
          className="fixed inset-0 -z-20"
          style={{ 
            backgroundImage: `url("/img/fondo.jpg")`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'black',
            filter: 'brightness(0.7) blur(1px)'
          }}
        />
        <div className="min-h-screen flex items-center justify-center">
          <div className="text-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-purple-500 mx-auto"></div>
            <p className="mt-4 text-white text-xl">Cargando ranking...</p>
          </div>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        {/* Fondo */}
        <div 
          className="fixed inset-0 -z-20"
          style={{ 
            backgroundImage: `url("/img/fondo.jpg")`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'black',
            filter: 'brightness(0.7) blur(1px)'
          }}
        />
        <div className="min-h-screen flex items-center justify-center">
          <div className="bg-black/75 backdrop-blur-sm p-8 rounded-xl shadow-2xl text-center max-w-md border border-purple-500/30">
            <h2 className="text-2xl font-bold text-white mb-4">Error</h2>
            <p className="text-gray-300 mb-6">{error}</p>
            <Link 
              to="/" 
              className="inline-block px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-all duration-200 transform hover:-translate-y-1"
            >
              Volver al inicio
            </Link>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {/* Fondo */}
      <div 
        className="fixed inset-0 -z-20"
        style={{ 
          backgroundImage: `url("/img/fondo.jpg")`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'black',
          filter: 'brightness(0.7) blur(1px)'
        }}
      />

      {/* Footer */}
      <footer 
        className="fixed bottom-0 left-0 right-0 h-[100px] z-[-10] pointer-events-none select-none"
        style={{
          background: 'url(/img/footer.png) no-repeat center center',
          backgroundSize: 'cover'
        }}
      />

      {/* Contenido principal */}
      <div className="min-h-screen relative z-10 py-12 px-4">
        <div className="max-w-6xl mx-auto bg-black/75 backdrop-blur-sm rounded-2xl p-8 shadow-2xl border border-purple-500/30">
          <h1 className="text-4xl font-bold bg-gradient-to-r from-purple-500 to-pink-500 bg-clip-text text-transparent text-center mb-12">
            Ranking de Avatares
          </h1>

          <div className="grid gap-4">
            {avatars.map((avatar, index) => (
              <Link
                key={avatar._id}
                to={`/misrraelita/${avatar.twitchUsername}`}
                className="bg-gray-800 rounded-lg shadow-xl overflow-hidden hover:transform hover:scale-[1.02] transition-all duration-200"
              >
                <div className="flex items-center p-6">
                  {/* Posición */}
                  <div className="flex-shrink-0 w-16 text-center">
                    <span className={`text-3xl font-bold ${
                      index === 0 ? 'text-yellow-400' :
                      index === 1 ? 'text-gray-400' :
                      index === 2 ? 'text-amber-600' :
                      'text-gray-500'
                    }`}>
                      #{index + 1}
                    </span>
                  </div>

                  {/* Avatar */}
                  <div className="flex-shrink-0 w-24 h-24 bg-gray-700 rounded-lg overflow-hidden">
                    <img
                      src={`${UPLOADS_URL}/saved-avatars/${avatar.avatarImage}`}
                      alt={avatar.twitchUsername}
                      className="w-full h-full object-contain"
                    />
                  </div>

                  {/* Info */}
                  <div className="ml-6 flex-1">
                    <h2 className="text-xl font-bold text-white">
                      {avatar.twitchUsername}
                    </h2>
                    <div className="mt-2 flex items-center">
                      <span className="text-3xl font-bold text-purple-400">
                        {avatar.totalPoints}
                      </span>
                      <span className="ml-2 text-gray-400">puntos</span>
                    </div>
                  </div>

                  {/* Rareza de partes */}
                  <div className="ml-6 grid grid-cols-2 gap-2">
                    {Object.entries(
                      avatar.layers.reduce((acc, layer) => {
                        acc[layer.rarity] = (acc[layer.rarity] || 0) + 1;
                        return acc;
                      }, {})
                    ).map(([rarity, count]) => (
                      <div 
                        key={rarity}
                        className={`px-3 py-1 rounded-full text-sm text-white text-center
                          ${rarity === 'común' ? 'bg-gray-500' : ''}
                          ${rarity === 'raro' ? 'bg-blue-500' : ''}
                          ${rarity === 'épico' ? 'bg-purple-500' : ''}
                          ${rarity === 'legendario' ? 'bg-yellow-500' : ''}
                          ${rarity === 'única' ? 'bg-red-500' : ''}
                        `}
                      >
                        {count}x {rarity}
                      </div>
                    ))}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default RankingPage; 