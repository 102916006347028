import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API_URL, UPLOADS_URL } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const HallOfFameModal = ({ isOpen, onClose }) => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMembers = async () => {
      if (!isOpen) return;
      
      try {
        setLoading(true);
        const response = await fetch(`${API_URL}/hall-of-fame`);
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || 'Error al cargar el Hall of Fame');
        }

        setMembers(data);
      } catch (error) {
        console.error('Error:', error);
        setError('Error al cargar el Hall of Fame');
      } finally {
        setLoading(false);
      }
    };

    fetchMembers();
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      <div 
        className="absolute inset-0 bg-black/80 backdrop-blur-sm animate-fadeIn"
        onClick={onClose}
      />
      
      <div className="relative bg-black/90 w-full max-w-4xl max-h-[90vh] overflow-hidden rounded-2xl shadow-2xl border border-purple-500/30 animate-modalOpen">
        <div className="flex justify-between items-center p-4 border-b border-purple-500/30">
          <h2 className="text-xl sm:text-2xl font-bold bg-gradient-to-r from-purple-500 to-pink-500 bg-clip-text text-transparent">
            Hall de la Fama
          </h2>
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <FontAwesomeIcon icon={faTimes} className="h-5 w-5" />
          </button>
        </div>

        <div className="p-4 border-b border-purple-500/30">
          <div className="text-gray-300">            
            <div className="bg-purple-900/20 rounded-xl p-4 border border-purple-500/30">
              <h3 className="text-base font-semibold text-purple-300 mb-2">
              ¡Bienvenido al Hall de la Fama!
              </h3>
              <div className="space-y-2 text-sm">
                <p>
                  Aquí encontrarás a los miembros más destacados de nuestra comunidad, aquellos que han dejado una marca especial y han contribuido significativamente a hacer de este espacio algo único.
                </p>
                <p>
                  Estos miembros han sido seleccionados por su dedicación, participación activa y el impacto positivo que han tenido en nuestra comunidad. Cada uno tiene su propia historia y ha ayudado a formar lo que somos hoy.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 overflow-y-auto max-h-[calc(90vh-280px)] scrollbar-thin scrollbar-track-black/30 scrollbar-thumb-purple-500/50">
          {loading ? (
            <div className="flex items-center justify-center p-4 text-gray-400">
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-purple-500 mr-2"></div>
              <span>Cargando miembros...</span>
            </div>
          ) : error ? (
            <div className="text-center text-red-400 p-4">{error}</div>
          ) : members.length === 0 ? (
            <div className="text-center text-gray-400 p-4">No hay miembros en el Hall de la Fama</div>
          ) : (
            <div className="grid gap-4">
              {members.map((member) => (
                <Link
                  key={member._id}
                  to={`/misrraelita/${member.twitchUsername}`}
                  onClick={onClose}
                  className="bg-gray-900/50 rounded-lg p-4 hover:bg-gray-900/70 transition-all duration-200"
                >
                  <div className="flex items-center">
                    {member.avatar && (
                      <div className="w-16 h-16 bg-gray-800 rounded-lg overflow-hidden">
                        <img
                          src={`${UPLOADS_URL}/saved-avatars/${member.avatar.avatarImage}`}
                          alt={member.twitchUsername}
                          className="w-full h-full object-contain"
                        />
                      </div>
                    )}
                    <div className="ml-4 flex-1">
                      <h3 className="text-lg font-bold text-white">
                        {member.twitchUsername}
                      </h3>
                      <p className="text-gray-400 text-sm mt-1">
                        {member.description}
                      </p>
                      <div className="text-gray-500 text-xs mt-2">
                        Miembro desde {new Date(member.addedAt).toLocaleDateString()}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HallOfFameModal; 