import React, { useState } from 'react';
import { API_URL, UPLOADS_URL } from '../config';

const AvatarSearch = ({ onAvatarLoad }) => {
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async (value) => {
    if (value.length < 2) {
      setSearchResults([]);
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_URL}/admin/search-avatars?search=${value}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setSearchResults(data);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className="relative z-50">
      <div className="relative">
        <input
          type="text"
          className="w-full px-4 py-2 bg-gray-600 text-white rounded-md border border-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 pl-10"
          placeholder="Buscar avatar por usuario..."
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            handleSearch(e.target.value);
          }}
        />
        <svg 
          className="absolute left-3 top-2.5 h-5 w-5 text-gray-400"
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" 
          />
        </svg>
      </div>

      {loading && (
        <div className="absolute w-full mt-2 bg-gray-700 rounded-md p-4 text-center shadow-lg">
          <div className="flex items-center justify-center">
            <svg 
              className="animate-spin h-5 w-5 text-purple-500 mr-2" 
              xmlns="http://www.w3.org/2000/svg" 
              fill="none" 
              viewBox="0 0 24 24"
            >
              <circle 
                className="opacity-25" 
                cx="12" 
                cy="12" 
                r="10" 
                stroke="currentColor" 
                strokeWidth="4"
              />
              <path 
                className="opacity-75" 
                fill="currentColor" 
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
            <span className="text-gray-300">Buscando...</span>
          </div>
        </div>
      )}

      {!loading && searchResults.length > 0 && (
        <div className="absolute w-full mt-2 bg-gray-700 rounded-md shadow-xl max-h-80 overflow-y-auto scrollbar-thin">
          {searchResults.map(avatar => (
            <button
              key={avatar._id}
              onClick={() => {
                onAvatarLoad(avatar);
                setSearch('');
                setSearchResults([]);
              }}
              className="w-full px-4 py-3 flex items-center space-x-4 hover:bg-gray-600 transition-colors duration-200 border-b border-gray-600 last:border-0"
            >
              <div className="flex-shrink-0 w-12 h-12 rounded-md overflow-hidden">
                <img
                  src={`${UPLOADS_URL}/saved-avatars/${avatar.avatarImage}`}
                  alt={avatar.twitchUsername}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="flex-1 text-left">
                <p className="text-white font-medium">{avatar.twitchUsername}</p>
                <p className="text-sm text-gray-400">
                  Actualizado: {formatDate(avatar.updatedAt)}
                </p>
              </div>
              <svg 
                className="w-5 h-5 text-gray-400" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M9 5l7 7-7 7" 
                />
              </svg>
            </button>
          ))}
        </div>
      )}

      {!loading && search.length >= 2 && searchResults.length === 0 && (
        <div className="absolute w-full mt-2 bg-gray-700 rounded-md p-4 text-center text-gray-400 shadow-lg">
          No se encontraron resultados
        </div>
      )}
    </div>
  );
};

export default AvatarSearch;