import React, { useState, useEffect, useCallback } from 'react';
import { API_URL, UPLOADS_URL } from '../config';
import { AnimatePresence, motion } from 'framer-motion';

const RecentActivityOverlay = () => {
  const [activities, setActivities] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const fetchActivity = async () => {
      try {
        const response = await fetch(`${API_URL}/activity/recent`, {
          signal: controller.signal,
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
          }
        });

        if (!response.ok) {
          throw new Error('Error al obtener actividad');
        }
        
        const data = await response.json();
        
        // Comparar solo los datos relevantes
        const currentActivitiesKey = activities.map(a => `${a.username}-${a.timestamp}`).join('|');
        const newActivitiesKey = data.map(a => `${a.username}-${a.timestamp}`).join('|');
        
        if (isMounted && currentActivitiesKey !== newActivitiesKey) {
          setActivities(data);
          setLastUpdate(new Date());
        }
      } catch (error) {
        if (error.name === 'AbortError') return;
        console.error('Error al obtener actividad reciente:', error);
      }
    };

    // Primera carga inmediata
    fetchActivity();

    // Polling más frecuente (2 segundos)
    const interval = setInterval(fetchActivity, 2000);

    return () => {
      isMounted = false;
      controller.abort();
      clearInterval(interval);
    };
  }, []); // Quitar activities y lastUpdate de las dependencias

  return (
    <div className="fixed bottom-4 left-4 right-4 z-50">
      <div className="bg-black/90 border border-purple-500/30 rounded-lg overflow-hidden shadow-2xl">
        <div className="bg-gradient-to-r from-purple-600 to-pink-600 px-4 py-2">
          <h2 className="text-white text-sm font-bold">Actividad Reciente</h2>
        </div>

        <div className="relative h-16 flex">
          <AnimatePresence initial={false}>
            {activities.slice(0, 4).map((activity, index) => (
              <motion.div
                key={`${activity.username}-${activity.timestamp}`}
                className="w-1/4 h-full border-r border-purple-500/20 last:border-r-0"
                initial={{ 
                  x: -100,
                  opacity: 0,
                  position: 'absolute',
                  left: 0,
                  width: '25%'
                }}
                animate={{ 
                  x: `${index * 100}%`,
                  opacity: 1,
                  position: 'absolute',
                  left: 0,
                  width: '25%'
                }}
                exit={{ 
                  x: '400%',
                  opacity: 0,
                  position: 'absolute',
                  width: '25%'
                }}
                transition={{
                  duration: 0.3,
                  ease: "easeInOut"
                }}
              >
                <div className="flex items-center h-full px-3 gap-3">
                  <div className="w-10 h-10 rounded-md overflow-hidden flex-shrink-0 bg-gray-800">
                    <img
                      src={`${UPLOADS_URL}/saved-avatars/${activity.avatarImage}`}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                  
                  <div className="min-w-0 flex-1">
                    <p className="text-white text-sm font-medium truncate">
                      {activity.username}
                    </p>
                    <div className="flex items-center gap-1.5 text-sm">
                      <span className="text-purple-400 font-bold">
                        {activity.points}PTS
                      </span>
                      {activity.ranking && (
                        <span className="text-gray-400">
                          #{activity.ranking}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default RecentActivityOverlay; 