import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import AvatarPreview from './AvatarPreview';
import CategoryManager from './CategoryManager';
import AvatarSearch from './AvatarSearch';
import { API_URL, UPLOADS_URL } from '../config';
import { Tab } from '@headlessui/react';
import Modal from './Modal';

const AdminPanel = () => {
  const [layers, setLayers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [newLayer, setNewLayer] = useState({
    name: '',
    category: '',
    rarity: 'común',
    file: null
  });
  const [editingLayer, setEditingLayer] = useState(null);
  const [multipleUploadSettings, setMultipleUploadSettings] = useState({
    category: '',
    rarity: 'común'
  });
  const [twitchUsername, setTwitchUsername] = useState('');
  const [activeCategory, setActiveCategory] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Número de items por página
  const navigate = useNavigate();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showMultipleUploadModal, setShowMultipleUploadModal] = useState(false);
  const [showSaveAvatarModal, setShowSaveAvatarModal] = useState(false);
  const [hallOfFameMembers, setHallOfFameMembers] = useState([]);
  const [newHallMember, setNewHallMember] = useState({
    twitchUsername: '',
    description: ''
  });
  const [rarityPoints, setRarityPoints] = useState({
    común: 0,
    raro: 0,
    épico: 0,
    legendario: 0,
    única: 0
  });
  const [rarityPointsHistory, setRarityPointsHistory] = useState([]);

  const getAuthHeaders = useCallback(() => {
    const token = localStorage.getItem('token');
    return {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    const fetchInitialData = async () => {
      try {
        const response = await fetch(`${API_URL}/categories/order`, {
          headers: getAuthHeaders()
        });
        if (response.ok) {
          const savedCategories = await response.json();
          setCategories(savedCategories);
        }
      } catch (error) {
        console.error('Error al cargar el orden de categorías:', error);
      }
    };

    fetchInitialData();
  }, [navigate, getAuthHeaders]);

  const fetchLayers = useCallback(async () => {
    if (!categories.length) return; // No cargar si no hay categorías

    try {
      const response = await fetch(`${API_URL}/layers`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      
      const validLayers = data
        .filter(layer => categories.includes(layer.category))
        .map(layer => ({ 
          ...layer, 
          isActive: false,
          zIndex: (categories.length - categories.indexOf(layer.category)) * 1000,
          imageUrl: `${UPLOADS_URL}/${layer.fileName}`
        }));

      setLayers(validLayers);
    } catch (error) {
      console.error('Error al cargar las capas:', error);
    }
  }, [categories]);

  useEffect(() => {
    if (categories.length > 0) {
      fetchLayers();
    }
  }, [categories, fetchLayers]);

  useEffect(() => {
    localStorage.removeItem('selectedLayers');
    fetchLayers();
  }, [fetchLayers]);

  useEffect(() => {
    const activeState = layers.reduce((acc, layer) => {
      acc[layer._id] = layer.isActive;
      return acc;
    }, {});
    localStorage.setItem('selectedLayers', JSON.stringify(activeState));
  }, [layers]);

  useEffect(() => {
    if (categories.length > 0 && !activeCategory) {
      setActiveCategory(categories[0]);
    }
  }, [categories, activeCategory]);

  const handleFileSelect = (file) => {
    if (!file) return;
    
    if (!file.type.includes('png')) {
      alert('Solo se permiten archivos PNG');
      return;
    }

    setNewLayer(prev => ({
      ...prev,
      file: file
    }));
  };

  const handleUpload = async () => {
    if (!newLayer.file) {
      alert('Por favor selecciona un archivo');
      return;
    }
    
    if (!newLayer.name.trim()) {
      alert('Por favor ingresa un nombre para la capa');
      return;
    }

    if (!newLayer.category) {
      alert('Por favor selecciona una categoría');
      return;
    }

    if (!categories.includes(newLayer.category)) {
      alert('La categoría seleccionada no es válida');
      return;
    }

    const formData = new FormData();
    formData.append('image', newLayer.file);
    formData.append('name', newLayer.name);
    formData.append('category', newLayer.category);
    formData.append('rarity', newLayer.rarity);
    
    try {
      const response = await fetch(`${API_URL}/layers/upload`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: formData
      });
      
      if (!response.ok) {
        throw new Error('Error al subir la capa');
      }

      const data = await response.json();
      setLayers(prev => [...prev, { ...data, isActive: true }]);
      setNewLayer({
        name: '',
        category: '',
        rarity: 'común',
        file: null
      });
    } catch (error) {
      console.error('Error:', error);
      alert('Error al subir la capa');
    }
  };



  // Filtrar las capas válidas para la vista previa
  const getValidLayers = () => {
    const validLayers = layers.filter(layer => 
      categories.includes(layer.category) && layer.isActive
    );
    return validLayers;
  };

  // Función para actualizar el zIndex de las capas basado en el orden de las categorías
  const updateLayersOrder = (categoryOrder) => {
    const newLayers = [...layers];
    
    // Asignar zIndex basado en la posición de la categoría
    // Las categorías más bajas en la lista tendrán zIndex más bajo (aparecerán detrás)
    categoryOrder.forEach((category, index) => {
      newLayers.forEach(layer => {
        if (layer.category === category) {
          layer.zIndex = index * 1000; // Multiplicamos por 1000 para dejar espacio entre categorías
        }
      });
    });

    setLayers(newLayers);
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(categories);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Actualizar estado local
    setCategories(items);
    
    // Actualizar las capas con el nuevo orden
    const newLayers = [...layers].map(layer => {
      const categoryIndex = items.indexOf(layer.category);
      const zIndex = (items.length - categoryIndex) * 1000;
      return {
        ...layer,
        zIndex: zIndex
      };
    });
    setLayers(newLayers);

    // Guardar el nuevo orden en el servidor
    try {
      await fetch(`${API_URL}/categories/order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ categories: items })
      });
    } catch (error) {
      console.error('Error al guardar el orden de categorías:', error);
    }
  };

  const handleLayerClick = (clickedLayer) => {
    setLayers(layers.map(layer => {
      if (layer.category === clickedLayer.category) {
        if (layer._id === clickedLayer._id) {
          const newState = { ...layer, isActive: !layer.isActive };
          return newState;
        }
        return { ...layer, isActive: false };
      }
      return layer;
    }));
  };

  const handleMultipleFileSelect = async (files) => {
    if (!files.length) return;
    
    if (!multipleUploadSettings.category) {
      alert('Por favor selecciona una categoría');
      return;
    }
    
    for (let file of files) {
      if (!file.type.includes('png')) {
        alert(`El archivo ${file.name} no es un PNG. Se omitirá.`);
        continue;
      }

      const formData = new FormData();
      formData.append('image', file);
      formData.append('name', file.name.replace('.png', ''));
      formData.append('category', multipleUploadSettings.category);
      formData.append('rarity', multipleUploadSettings.rarity);
      
      try {
        const response = await fetch(`${API_URL}/layers/upload`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: formData
        });
        const data = await response.json();
        if (categories.includes(data.category)) {
          setLayers(prev => [...prev, { ...data, isActive: true }]);
        }
      } catch (error) {
        console.error(`Error al subir ${file.name}:`, error);
      }
    }
  };

  const handleUpdateLayerName = async (layerId, newName) => {
    try {
      const response = await fetch(`${API_URL}/layers/${layerId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: newName })
      });
      
      if (response.ok) {
        setLayers(layers.map(layer => 
          layer._id === layerId ? { ...layer, name: newName } : layer
        ));
        setEditingLayer(null);
      }
    } catch (error) {
      console.error('Error al actualizar el nombre:', error);
    }
  };

  const rarityOptions = [
    { value: 'común', label: 'Común (50%)', weight: 100 },
    { value: 'raro', label: 'Raro (30%)', weight: 60 },
    { value: 'épico', label: 'Épico (15%)', weight: 30 },
    { value: 'legendario', label: 'Legendario (5%)', weight: 10 },
    { value: 'única', label: 'Única (Manual)', weight: 0 }
  ];

  // Modificar la función generateRandomAvatar
  const generateRandomAvatar = () => {
    const newLayers = [...layers];
    const categorizedLayers = {};
    
    // Agrupar capas por categoría
    layers.forEach(layer => {
      if (!categorizedLayers[layer.category]) {
        categorizedLayers[layer.category] = [];
      }
      // Solo incluir capas que no sean únicas
      if (layer.rarity !== 'única') {
        categorizedLayers[layer.category].push(layer);
      }
    });

    // Para cada categoría, seleccionar una capa basada en rareza
    Object.keys(categorizedLayers).forEach(category => {
      const categoryLayers = categorizedLayers[category];
      
      if (categoryLayers.length === 0) return; // Saltar si no hay capas no-únicas

      // Calcular el peso total de la categoría
      const totalWeight = categoryLayers.reduce((sum, layer) => {
        const weight = rarityOptions.find(r => r.value === layer.rarity)?.weight || 0;
        return sum + weight;
      }, 0);

      // Generar número aleatorio
      let random = Math.random() * totalWeight;
      let selectedLayer = null;

      // Seleccionar capa basada en peso
      for (const layer of categoryLayers) {
        const weight = rarityOptions.find(r => r.value === layer.rarity)?.weight || 0;
        random -= weight;
        if (random <= 0) {
          selectedLayer = layer;
          break;
        }
      }

      // Activar la capa seleccionada y desactivar las demás
      if (selectedLayer) {
        categoryLayers.forEach(layer => {
          const index = newLayers.findIndex(l => l._id === layer._id);
          if (index !== -1) {
            newLayers[index] = {
              ...newLayers[index],
              isActive: layer._id === selectedLayer._id
            };
          }
        });
      }
    });

    setLayers(newLayers);
  };

  // Función para actualizar la rareza de una capa
  const handleUpdateLayerRarity = async (layerId, newRarity) => {
    try {
      const response = await fetch(`${API_URL}/layers/${layerId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ rarity: newRarity })
      });
      
      if (response.ok) {
        const updatedLayer = await response.json();
        setLayers(layers.map(layer => 
          layer._id === layerId ? { ...layer, ...updatedLayer } : layer
        ));
      }
    } catch (error) {
      console.error('Error al actualizar la rareza:', error);
    }
  };

  // Agregar la función de eliminación
  const handleDeleteLayer = async (layerId) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar esta capa?')) {
      try {
        const response = await fetch(`${API_URL}/layers/${layerId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (response.ok) {
          setLayers(layers.filter(layer => layer._id !== layerId));
          setEditingLayer(null);
        } else {
          alert('Error al eliminar la capa');
        }
      } catch (error) {
        console.error('Error al eliminar la capa:', error);
        alert('Error al eliminar la capa');
      }
    }
  };

  // Función para guardar el avatar
  const saveAvatar = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No hay sesión activa');
      }

      const activeLayers = layers
        .filter(layer => layer.isActive)
        .map(layer => ({
          layerId: layer._id,
          isActive: layer.isActive,
          zIndex: layer.zIndex
        }));

      const response = await fetch(`${API_URL}/avatars`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          twitchUsername,
          layers: activeLayers
        })
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Error al guardar el avatar');
      }

      alert('Avatar guardado correctamente');
      
      // Limpiar el formulario
      setTwitchUsername('');
    } catch (error) {
      console.error('Error al guardar:', error);
      alert(error.message || 'Error al guardar el avatar');
    }
  };

  const loadAvatar = async (avatar) => {
    try {
      // Asegurarnos de que tenemos las categorías
      let currentCategories = categories;
      if (!currentCategories.length) {
        const categoriesResponse = await fetch(`${API_URL}/categories/order`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (categoriesResponse.ok) {
          currentCategories = await categoriesResponse.json();
          setCategories(currentCategories);
        }
      }

      // Cargar las capas
      const layersResponse = await fetch(`${API_URL}/layers`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (!layersResponse.ok) {
        throw new Error('Error al cargar las capas');
      }

      const allLayers = await layersResponse.json();

      // Actualizar las capas manteniendo el orden y el estado activo
      const newLayers = allLayers.map(layer => {
        const savedLayer = avatar.layers.find(l => 
          l.layerId === layer._id || 
          (l.layerId && l.layerId._id === layer._id)
        );

        const categoryIndex = currentCategories.indexOf(layer.category);
        const zIndex = categoryIndex !== -1 ? 
          (currentCategories.length - categoryIndex) * 1000 : 
          0;

        return {
          ...layer,
          isActive: savedLayer ? savedLayer.isActive : false,
          zIndex,
          imageUrl: `${UPLOADS_URL}/${layer.fileName}`
        };
      });

      // Actualizar el estado de forma síncrona
      setLayers(newLayers);
      setTwitchUsername(avatar.twitchUsername || '');

    } catch (error) {
      console.error('Error detallado:', error);
      alert('Error al cargar el avatar: ' + error.message);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const getCurrentPageItems = (items) => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return items.slice(indexOfFirstItem, indexOfLastItem);
  };

  const handleDownloadAvatar = () => {
    const canvas = document.createElement('canvas');
    canvas.width = 800;
    canvas.height = 800;
    const ctx = canvas.getContext('2d');

    const sortedLayers = [...getValidLayers()].sort((a, b) => a.zIndex - b.zIndex);
    
    const drawLayer = (index) => {
      if (index >= sortedLayers.length) {
        const link = document.createElement('a');
        link.download = 'avatar-misrravb.png';
        link.href = canvas.toDataURL('image/png');
        link.click();
        return;
      }

      const layer = sortedLayers[index];
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        ctx.drawImage(img, 0, 0, 800, 800);
        drawLayer(index + 1);
      };
      img.src = layer.imageUrl;
    };

    drawLayer(0);
  };

  const fetchHallOfFameMembers = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/hall-of-fame`, {
        headers: getAuthHeaders()
      });
      
      if (!response.ok) {
        throw new Error('Error al cargar Hall of Fame');
      }
      
      const data = await response.json();
      
      // Asegurarse de que data es un array
      if (!Array.isArray(data)) {
        console.error('La respuesta no es un array:', data);
        setHallOfFameMembers([]);
        return;
      }
      
      setHallOfFameMembers(data);
    } catch (error) {
      console.error('Error al cargar Hall of Fame:', error);
      setHallOfFameMembers([]); // En caso de error, establecer array vacío
    }
  }, [getAuthHeaders]);

  useEffect(() => {
    fetchHallOfFameMembers();
  }, [fetchHallOfFameMembers]);

  const addHallOfFameMember = async () => {
    try {
      const response = await fetch(`${API_URL}/hall-of-fame`, {
        method: 'POST',
        headers: {
          ...getAuthHeaders(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newHallMember)
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Error al añadir miembro');
      }

      await fetchHallOfFameMembers();
      setNewHallMember({ twitchUsername: '', description: '' });
    } catch (error) {
      console.error('Error:', error);
      alert(error.message);
    }
  };

  const removeHallOfFameMember = async (username) => {
    if (!window.confirm('¿Estás seguro de que quieres eliminar este miembro?')) return;

    try {
      const response = await fetch(`${API_URL}/hall-of-fame/${username}`, {
        method: 'DELETE',
        headers: getAuthHeaders()
      });

      if (!response.ok) {
        throw new Error('Error al eliminar miembro');
      }

      await fetchHallOfFameMembers();
    } catch (error) {
      console.error('Error:', error);
      alert(error.message);
    }
  };

  const handleRarityPointChange = (rarity, newPoints) => {
    setRarityPoints(prev => ({
      ...prev,
      [rarity]: newPoints
    }));
  };

  const fetchRarityPoints = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/rarity-points`, {
        headers: getAuthHeaders()
      });
      
      if (!response.ok) {
        throw new Error('Error al cargar puntos de rareza');
      }
      
      const data = await response.json();
      setRarityPoints({
        común: data.común || 0,
        raro: data.raro || 0,
        épico: data.épico || 0,
        legendario: data.legendario || 0,
        única: data.única || 0
      });
    } catch (error) {
      console.error('Error al cargar puntos de rareza:', error);
    }
  }, [getAuthHeaders]);

  const fetchRarityPointsHistory = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/rarity-points/history`, {
        headers: getAuthHeaders()
      });
      
      if (!response.ok) {
        throw new Error('Error al cargar historial de puntos');
      }
      
      const data = await response.json();
      setRarityPointsHistory(data);
    } catch (error) {
      console.error('Error al cargar historial de puntos:', error);
    }
  }, [getAuthHeaders]);

  useEffect(() => {
    fetchRarityPoints();
    fetchRarityPointsHistory();
  }, [fetchRarityPoints, fetchRarityPointsHistory]);

  const saveRarityPoints = async () => {
    try {
      const response = await fetch(`${API_URL}/rarity-points`, {
        method: 'POST',
        headers: {
          ...getAuthHeaders(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(rarityPoints)
      });

      const data = await response.json();

      if (!response.ok) {
        // Si el error es que no hay cambios, mostrar un mensaje específico
        if (data.message) {
          alert(data.message);
          return;
        }
        throw new Error(data.error || 'Error al guardar los puntos de rareza');
      }

      setRarityPoints(data);
      fetchRarityPointsHistory();
      alert('Puntos actualizados correctamente');
      
    } catch (error) {
      console.error('Error al guardar los puntos de rareza:', error);
      alert(error.message || 'Error al guardar los puntos de rareza');
    }
  };

  return (
    <div className="min-h-screen bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="flex justify-between items-center mb-8 bg-gray-800 p-6 rounded-lg shadow-lg">
          <h1 className="text-3xl font-bold text-white">
            Panel de Administración
          </h1>
          <button 
            onClick={handleLogout}
            className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors duration-200"
          >
            Cerrar Sesión
          </button>
        </div>


        {/* Nueva sección de gestión de avatares */}
        <div className="bg-gray-800 rounded-lg shadow-lg p-6 mb-8">
          <h2 className="text-2xl font-bold text-white mb-6">Gestión de Avatares</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Save Avatar Section */}
            <div className="bg-gray-700 rounded-lg p-6">
              <h3 className="text-xl font-semibold text-white mb-4">Guardar Avatar</h3>
              <div className="flex gap-4">
                <input
                  type="text"
                  value={twitchUsername}
                  onChange={(e) => setTwitchUsername(e.target.value)}
                  placeholder="Usuario"
                  className="flex-1 px-4 py-2 bg-gray-600 text-white rounded-md border border-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
                <button 
                  onClick={saveAvatar}
                  disabled={!twitchUsername.trim()}
                  className="px-6 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Guardar Avatar
                </button>
              </div>
            </div>

            {/* Search Avatar Section */}
            <div className="bg-gray-700 rounded-lg p-6">
              <h3 className="text-xl font-semibold text-white mb-4">Buscar Avatares</h3>
              <AvatarSearch onAvatarLoad={loadAvatar} />
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <Tab.Group>
              <Tab.List className="flex space-x-2 bg-gray-800 p-2 rounded-lg mb-4">
                <Tab className={({ selected }) => `
                  flex-1 py-2 px-4 rounded-lg text-white
                  ${selected ? 'bg-purple-600' : 'bg-gray-700 hover:bg-gray-600'}
                `}>
                  Capas
                </Tab>
                <Tab className={({ selected }) => `
                  flex-1 py-2 px-4 rounded-lg text-white
                  ${selected ? 'bg-purple-600' : 'bg-gray-700 hover:bg-gray-600'}
                `}>
                  Categorías
                </Tab>
                <Tab className={({ selected }) => `
                  flex-1 py-2 px-4 rounded-lg text-white
                  ${selected ? 'bg-purple-600' : 'bg-gray-700 hover:bg-gray-600'}
                `}>
                  Hall of Fame
                </Tab>
                <Tab className={({ selected }) => `
                  flex-1 py-2 px-4 rounded-lg text-white
                  ${selected ? 'bg-purple-600' : 'bg-gray-700 hover:bg-gray-600'}
                `}>
                  Puntos de Rareza
                </Tab>
              </Tab.List>

              <Tab.Panels>
                {/* Panel de Capas */}
                <Tab.Panel>
                  <div className="space-y-6">
                    {/* Botones de acción */}
                    <div className="flex justify-between mb-4">
                      <button onClick={() => setShowUploadModal(true)}
                        className="px-4 py-2 bg-purple-600 text-white rounded-md">
                        Nueva Capa
                      </button>
                      <button onClick={() => setShowMultipleUploadModal(true)}
                        className="px-4 py-2 bg-blue-600 text-white rounded-md">
                        Subida Múltiple
                      </button>
                    </div>

                    {/* Navegación de categorías */}
                    <div className="relative">
                      <div className="flex overflow-x-auto custom-scrollbar pb-2 mb-4 -mx-2 px-2">
                        <div className="flex space-x-1 min-w-full">
                          {categories.map(category => (
                            <button
                              key={category}
                              onClick={() => {
                                setActiveCategory(category);
                                setCurrentPage(1);
                              }}
                              className={`
                                px-3 py-1.5 rounded-md text-sm whitespace-nowrap transition-colors duration-200
                                ${activeCategory === category 
                                  ? 'bg-purple-600 text-white font-medium' 
                                  : 'bg-gray-700 text-gray-300 hover:bg-gray-600 hover:text-white'
                                }
                              `}
                            >
                              {category}
                              <span className="ml-1.5 px-1.5 py-0.5 text-xs rounded-full bg-gray-800/50">
                                {layers.filter(l => l.category === category).length}
                              </span>
                            </button>
                          ))}
                        </div>
                      </div>
                      
                      <div className="absolute left-0 top-0 bottom-2 w-8 bg-gradient-to-r from-gray-900 to-transparent pointer-events-none" />
                      <div className="absolute right-0 top-0 bottom-2 w-8 bg-gradient-to-l from-gray-900 to-transparent pointer-events-none" />
                    </div>

                    {/* Grid de capas con previews - Ajustado para más columnas y tamaño más pequeño */}
                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-3">
                      {getCurrentPageItems(layers.filter(layer => !activeCategory || layer.category === activeCategory))
                        .map(layer => (
                          <div 
                            key={layer._id} 
                            className={`
                              bg-gray-700 rounded-lg p-2 relative transition-all duration-200
                              ${layer.isActive ? 'ring-2 ring-purple-500' : ''}
                              hover:shadow-lg
                            `}
                          >
                            {/* Preview de la capa con interacción - Tamaño reducido */}
                            <div 
                              className="relative aspect-square mb-2 cursor-pointer group"
                              onClick={() => handleLayerClick(layer)}
                            >
                              <img 
                                src={layer.imageUrl} 
                                alt={layer.name}
                                className={`
                                  w-full h-full object-contain rounded-md transition-opacity duration-200
                                  ${!layer.isActive ? 'opacity-50' : ''}
                                  group-hover:opacity-90
                                `}
                              />
                              {layer.isActive && (
                                <div className="absolute top-1 right-1 bg-purple-500 rounded-full p-0.5">
                                  <svg className="w-3 h-3 text-white" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
                                  </svg>
                                </div>
                              )}
                            </div>

                            {/* Información y controles de la capa - Texto más compacto */}
                            <div className="space-y-1">
                              <div className="flex justify-between items-center">
                                <span className="text-xs text-white font-medium truncate">{layer.name}</span>
                                <button
                                  onClick={() => setEditingLayer(layer._id)}
                                  className="p-0.5 hover:bg-gray-600 rounded transition-colors duration-200"
                                >
                                  <svg className="w-3 h-3 text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                  </svg>
                                </button>
                              </div>

                              {/* Etiqueta de rareza - Más compacta */}
                              <div className={`
                                text-xs px-1.5 py-0.5 rounded-full text-center text-white text-[10px]
                                ${layer.rarity === 'común' ? 'bg-gray-500' : ''}
                                ${layer.rarity === 'raro' ? 'bg-blue-500' : ''}
                                ${layer.rarity === 'épico' ? 'bg-purple-500' : ''}
                                ${layer.rarity === 'legendario' ? 'bg-yellow-500' : ''}
                                ${layer.rarity === 'única' ? 'bg-red-500' : ''}
                              `}>
                                {layer.rarity}
                              </div>
                            </div>

                            {/* Modo de edición - Panel más compacto */}
                            {editingLayer === layer._id && (
                              <div className="absolute inset-0 bg-gray-800 bg-opacity-95 p-2 rounded-lg space-y-2">
                                <input
                                  type="text"
                                  value={layer.name}
                                  onChange={(e) => {
                                    setLayers(layers.map(l => 
                                      l._id === layer._id ? { ...l, name: e.target.value } : l
                                    ));
                                  }}
                                  className="w-full px-2 py-1 text-xs bg-gray-700 text-white rounded border border-gray-600 focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                                  onBlur={() => handleUpdateLayerName(layer._id, layer.name)}
                                />
                                <select
                                  value={layer.rarity}
                                  onChange={(e) => handleUpdateLayerRarity(layer._id, e.target.value)}
                                  className="w-full px-2 py-1 text-xs bg-gray-700 text-white rounded border border-gray-600 focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                                >
                                  {rarityOptions.map(option => (
                                    <option key={option.value} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                                <div className="flex justify-end space-x-1 pt-1">
                                  <button 
                                    onClick={() => setEditingLayer(null)}
                                    className="px-2 py-0.5 text-xs bg-gray-600 text-white rounded hover:bg-gray-500 transition-colors duration-200"
                                  >
                                    Cancelar
                                  </button>
                                  <button 
                                    onClick={() => handleDeleteLayer(layer._id)}
                                    className="px-2 py-0.5 text-xs bg-red-600 text-white rounded hover:bg-red-700 transition-colors duration-200"
                                  >
                                    Eliminar
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                      ))}
                    </div>

                    {/* Paginación */}
                    <div className="flex justify-center items-center space-x-4 mt-6">
                      <button
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                        className="px-3 py-1 bg-gray-600 text-white rounded-md disabled:opacity-50"
                      >
                        ←
                      </button>
                      <div className="flex space-x-2">
                        {Array.from({ length: Math.ceil(layers.filter(layer => !activeCategory || layer.category === activeCategory).length / itemsPerPage) }, (_, i) => (
                          <button
                            key={i}
                            onClick={() => setCurrentPage(i + 1)}
                            className={`
                              w-2 h-2 rounded-full transition-colors
                              ${currentPage === i + 1 ? 'bg-purple-500' : 'bg-gray-500'}
                            `}
                          />
                        ))}
                      </div>
                      <button
                        onClick={() => setCurrentPage(prev => prev + 1)}
                        disabled={currentPage >= Math.ceil(layers.filter(layer => !activeCategory || layer.category === activeCategory).length / itemsPerPage)}
                        className="px-3 py-1 bg-gray-600 text-white rounded-md disabled:opacity-50"
                      >
                        →
                      </button>
                    </div>
                  </div>
                </Tab.Panel>

                {/* Panel de Categorías */}
                <Tab.Panel>
                  <div className="bg-yellow-600 text-white p-4 rounded-lg mb-4">
                    <p className="font-bold">⚠️ Advertencia</p>
                    <p className="text-sm">
                      La modificación de categorías puede afectar a todos los avatares existentes. 
                      Proceda con precaución.
                    </p>
                  </div>
                  <CategoryManager 
                    categories={categories}
                    setCategories={setCategories}
                    onDragEnd={handleDragEnd}
                  />
                </Tab.Panel>

                {/* Nuevo Panel para Hall of Fame */}
                <Tab.Panel>
                  <div className="space-y-6">
                    {/* Formulario para añadir nuevo miembro */}
                    <div className="bg-gray-700 rounded-lg p-6">
                      <h3 className="text-xl font-semibold text-white mb-4">Añadir Nuevo Miembro</h3>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <input
                          type="text"
                          value={newHallMember.twitchUsername}
                          onChange={(e) => setNewHallMember(prev => ({
                            ...prev,
                            twitchUsername: e.target.value
                          }))}
                          placeholder="Usuario de Twitch"
                          className="px-4 py-2 bg-gray-600 text-white rounded-md border border-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500"
                        />
                        <input
                          type="text"
                          value={newHallMember.description}
                          onChange={(e) => setNewHallMember(prev => ({
                            ...prev,
                            description: e.target.value
                          }))}
                          placeholder="Descripción"
                          className="px-4 py-2 bg-gray-600 text-white rounded-md border border-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500"
                        />
                      </div>
                      <button
                        onClick={addHallOfFameMember}
                        disabled={!newHallMember.twitchUsername.trim()}
                        className="mt-4 px-6 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        Añadir al Hall of Fame
                      </button>
                    </div>

                    {/* Lista de miembros actuales */}
                    <div className="bg-gray-700 rounded-lg p-6">
                      <h3 className="text-xl font-semibold text-white mb-4">Miembros Actuales</h3>
                      <div className="space-y-4">
                        {Array.isArray(hallOfFameMembers) && hallOfFameMembers.map((member) => (
                          <div
                            key={member._id}
                            className="bg-gray-800 rounded-lg p-4 flex items-center justify-between"
                          >
                            <div className="flex items-center space-x-4">
                              {member.avatar && (
                                <div className="w-12 h-12 bg-gray-900 rounded-lg overflow-hidden">
                                  <img
                                    src={`${UPLOADS_URL}/saved-avatars/${member.avatar.avatarImage}`}
                                    alt={member.twitchUsername}
                                    className="w-full h-full object-contain"
                                  />
                                </div>
                              )}
                              <div>
                                <h4 className="text-white font-medium">{member.twitchUsername}</h4>
                                <p className="text-gray-400 text-sm">{member.description}</p>
                              </div>
                            </div>
                            <div className="flex items-center space-x-4">
                              <span className="text-gray-400 text-sm">
                                {new Date(member.addedAt).toLocaleDateString()}
                              </span>
                              <button
                                onClick={() => removeHallOfFameMember(member.twitchUsername)}
                                className="p-2 text-red-400 hover:text-red-300 transition-colors duration-200"
                              >
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Tab.Panel>

                {/* Nuevo Panel para Puntos de Rareza */}
                <Tab.Panel>
                  <div className="space-y-6">
                    <div className="bg-yellow-600 text-white p-4 rounded-lg mb-4">
                      <p className="font-bold">⚠️ Advertencia</p>
                      <p className="text-sm">
                        La modificación de los puntos de rareza afectará a todos los rankings y puntuaciones.
                        Proceda con precaución.
                      </p>
                    </div>

                    <div className="bg-gray-700 rounded-lg p-6">
                      <h3 className="text-xl font-semibold text-white mb-4">Configuración de Puntos</h3>
                      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                        {rarityPoints && Object.entries(rarityPoints).map(([rarity, points]) => (
                          <div key={rarity} className="bg-gray-800 rounded-lg p-4">
                            <label className="block text-sm font-medium text-gray-300 mb-2">
                              {rarity.charAt(0).toUpperCase() + rarity.slice(1)}
                            </label>
                            <div className="flex items-center space-x-2">
                              <input
                                type="number"
                                value={points}
                                onChange={(e) => handleRarityPointChange(rarity, parseInt(e.target.value))}
                                className="w-full px-3 py-2 bg-gray-700 text-white rounded-md border border-gray-600 focus:outline-none focus:ring-2 focus:ring-purple-500"
                                min="0"
                                max="1000"
                              />
                              <span className="text-gray-400">pts</span>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="mt-6 flex justify-end">
                        <button
                          onClick={saveRarityPoints}
                          className="px-6 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors duration-200"
                        >
                          Guardar Cambios
                        </button>
                      </div>
                    </div>

                    <div className="bg-gray-700 rounded-lg p-6">
                      <h3 className="text-xl font-semibold text-white mb-4">Historial de Cambios</h3>
                      <div className="space-y-3">
                        {rarityPointsHistory.map((history, index) => (
                          <div key={index} className="bg-gray-800 rounded-lg p-4">
                            <div className="flex justify-between items-start">
                              <div>
                                <span className="text-gray-300">Actualizado por: {history.updatedBy}</span>
                                <div className="mt-2 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-2">
                                  {Object.entries(history).map(([key, value]) => {
                                    if (!['_id', 'updatedAt', 'updatedBy', '__v'].includes(key)) {
                                      return (
                                        <div key={key} className="text-sm">
                                          <span className="text-gray-400">{key}: </span>
                                          <span className="text-purple-400 font-medium">{value}pts</span>
                                        </div>
                                      );
                                    }
                                    return null;
                                  })}
                                </div>
                              </div>
                              <span className="text-sm text-gray-400">
                                {new Date(history.updatedAt).toLocaleDateString()}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>

          {/* Panel derecho - Preview y Estadísticas */}
          <div className="space-y-8">
            {/* Preview */}
            <div className="bg-gray-800 rounded-lg shadow-lg p-6">
              <AvatarPreview 
                layers={getValidLayers()} 
                onGenerateRandom={generateRandomAvatar}
              />
              <div className="mt-6 space-y-4">
                <button 
                  onClick={generateRandomAvatar}
                  className="w-full py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
                >
                  Generar Aleatorio
                </button>
                <button 
                  onClick={handleDownloadAvatar}
                  className="w-full py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors duration-200"
                >
                  Descargar Avatar
                </button>
              </div>
            </div>

            {/* Estadísticas - Versión compacta */}
            <div className="bg-gray-800 rounded-lg shadow-lg p-4">
              <h2 className="text-lg font-bold text-white mb-2">Estadísticas</h2>
              <div className="grid grid-cols-2 gap-2 text-sm">
                <div className="bg-gray-700 p-2 rounded">
                  <div className="text-gray-400">Total Capas</div>
                  <div className="text-white font-bold">{getValidLayers().length}</div>
                </div>
                <div className="bg-gray-700 p-2 rounded">
                  <div className="text-gray-400">Categorías</div>
                  <div className="text-white font-bold">{categories.length}</div>
                </div>
                {Object.entries(
                  getValidLayers().reduce((acc, layer) => {
                    acc[layer.rarity] = (acc[layer.rarity] || 0) + 1;
                    return acc;
                  }, {})
                ).map(([rarity, count]) => (
                  <div key={rarity} className="bg-gray-700 p-2 rounded">
                    <div className="text-gray-400 capitalize">{rarity}</div>
                    <div className="text-white font-bold">{count}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modales */}
      <Modal show={showUploadModal} onClose={() => setShowUploadModal(false)}>
        <div className="space-y-4">
          <h3 className="text-xl text-white font-bold">Nueva Capa</h3>
          <input
            type="text"
            placeholder="Nombre de la capa"
            value={newLayer.name}
            onChange={(e) => setNewLayer({...newLayer, name: e.target.value})}
            className="w-full bg-gray-700 text-white px-3 py-2 rounded"
          />
          <select
            value={newLayer.category}
            onChange={(e) => setNewLayer({...newLayer, category: e.target.value})}
            className="w-full bg-gray-700 text-white px-3 py-2 rounded"
          >
            <option value="">Selecciona categoría</option>
            {categories.map(cat => (
              <option key={cat} value={cat}>{cat}</option>
            ))}
          </select>
          <select
            value={newLayer.rarity}
            onChange={(e) => setNewLayer({...newLayer, rarity: e.target.value})}
            className="w-full bg-gray-700 text-white px-3 py-2 rounded"
          >
            {rarityOptions.map(option => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
          <input
            type="file"
            accept=".png"
            onChange={(e) => handleFileSelect(e.target.files[0])}
            className="w-full text-white"
          />
          <button
            onClick={handleUpload}
            className="w-full px-4 py-2 bg-purple-600 text-white rounded-md"
          >
            Subir Capa
          </button>
        </div>
      </Modal>

      <Modal show={showMultipleUploadModal} onClose={() => setShowMultipleUploadModal(false)}>
        <div className="space-y-4">
          <h3 className="text-xl text-white font-bold">Subida Múltiple</h3>
          <select
            value={multipleUploadSettings.category}
            onChange={(e) => setMultipleUploadSettings({...multipleUploadSettings, category: e.target.value})}
            className="w-full bg-gray-700 text-white px-3 py-2 rounded"
          >
            <option value="">Selecciona categoría</option>
            {categories.map(cat => (
              <option key={cat} value={cat}>{cat}</option>
            ))}
          </select>
          <select
            value={multipleUploadSettings.rarity}
            onChange={(e) => setMultipleUploadSettings({...multipleUploadSettings, rarity: e.target.value})}
            className="w-full bg-gray-700 text-white px-3 py-2 rounded"
          >
            {rarityOptions.map(option => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
          <input
            type="file"
            accept=".png"
            multiple
            onChange={(e) => handleMultipleFileSelect(Array.from(e.target.files))}
            className="w-full text-white"
          />
        </div>
      </Modal>

      <Modal show={showSaveAvatarModal} onClose={() => setShowSaveAvatarModal(false)}>
        <div className="space-y-4">
          <h3 className="text-xl text-white font-bold">Guardar Avatar</h3>
          <input
            type="text"
            placeholder="Usuario de Twitch"
            value={twitchUsername}
            onChange={(e) => setTwitchUsername(e.target.value)}
            className="w-full bg-gray-700 text-white px-3 py-2 rounded"
          />
          <button
            onClick={saveAvatar}
            className="w-full px-4 py-2 bg-purple-600 text-white rounded-md"
          >
            Guardar
          </button>
        </div>
      </Modal>

      <style>
        {`
          .custom-scrollbar {
            -ms-overflow-style: none;
            scrollbar-width: none;
          }
          .custom-scrollbar::-webkit-scrollbar {
            display: none;
          }
        `}
      </style>
    </div>
  );
};

export default AdminPanel;