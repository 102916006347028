import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { API_URL, UPLOADS_URL } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const RankingModal = ({ isOpen, onClose }) => {
  const [avatars, setAvatars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAvatars = async () => {
      if (!isOpen) return;
      
      try {
        setLoading(true);
        const response = await fetch(`${API_URL}/avatars/ranking`);
        
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Error al cargar el ranking');
        }

        const data = await response.json();

        if (!Array.isArray(data)) {
          console.error('Datos recibidos no son un array:', data);
          setAvatars([]);
          return;
        }

        setAvatars(data);
      } catch (error) {
        console.error('Error detallado al cargar el ranking:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAvatars();
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      <div 
        className="absolute inset-0 bg-black/80 backdrop-blur-sm animate-fadeIn"
        onClick={onClose}
      />
      
      <div className="relative bg-black/90 w-full max-w-4xl max-h-[90vh] overflow-hidden rounded-2xl shadow-2xl border border-purple-500/30 animate-modalOpen">
        <div className="flex justify-between items-center p-4 border-b border-purple-500/30">
          <h2 className="text-xl sm:text-2xl font-bold bg-gradient-to-r from-purple-500 to-pink-500 bg-clip-text text-transparent">
            Ranking de Avatares
          </h2>
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <FontAwesomeIcon icon={faTimes} className="h-5 w-5" />
          </button>
        </div>

        <div className="p-4 overflow-y-auto max-h-[calc(90vh-80px)] scrollbar-thin scrollbar-track-black/30 scrollbar-thumb-purple-500/50">
          {loading ? (
            <div className="flex items-center justify-center p-4 text-gray-400">
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-purple-500 mr-2"></div>
              <span>Cargando ranking...</span>
            </div>
          ) : (
            <div className="grid gap-3">
              {avatars.map((avatar, index) => (
                <Link
                  key={avatar._id}
                  to={`/misrraelita/${avatar.twitchUsername}`}
                  onClick={onClose}
                  className="bg-gray-900/50 rounded-lg shadow-xl overflow-hidden hover:bg-gray-900/70 transition-all duration-200"
                >
                  <div className="flex items-center p-3 sm:p-4">
                    <div className="flex-shrink-0 w-10 sm:w-12 text-center">
                      <span className={`text-lg sm:text-2xl font-bold ${
                        index === 0 ? 'text-yellow-400' :
                        index === 1 ? 'text-gray-400' :
                        index === 2 ? 'text-amber-600' :
                        'text-gray-500'
                      }`}>
                        #{index + 1}
                      </span>
                    </div>

                    <div className="flex-shrink-0 w-12 h-12 sm:w-16 sm:h-16 bg-gray-800 rounded-lg overflow-hidden ml-2 sm:ml-4">
                      <img
                        src={`${UPLOADS_URL}/saved-avatars/${avatar.avatarImage}`}
                        alt={avatar.twitchUsername}
                        className="w-full h-full object-contain"
                      />
                    </div>

                    <div className="ml-3 sm:ml-4 flex-1 min-w-0">
                      <h2 className="text-sm sm:text-lg font-bold text-white truncate">
                        {avatar.twitchUsername}
                      </h2>
                      <div className="flex items-center mt-0.5">
                        <span className="text-base sm:text-2xl font-bold text-purple-400">
                          {avatar.totalPoints}
                        </span>
                        <span className="ml-1 sm:ml-2 text-xs sm:text-sm text-gray-400">pts</span>
                      </div>
                    </div>

                    <div className="hidden sm:grid grid-cols-2 gap-1.5 ml-4">
                      {Object.entries(
                        avatar.layers.reduce((acc, layer) => {
                          acc[layer.rarity] = (acc[layer.rarity] || 0) + 1;
                          return acc;
                        }, {})
                      ).map(([rarity, count]) => (
                        <div 
                          key={rarity}
                          className={`px-2 py-0.5 rounded-full text-xs flex items-center justify-center
                            ${rarity === 'común' ? 'bg-gray-500/20 text-gray-300' : ''}
                            ${rarity === 'raro' ? 'bg-blue-500/20 text-blue-300' : ''}
                            ${rarity === 'épico' ? 'bg-purple-500/20 text-purple-300' : ''}
                            ${rarity === 'legendario' ? 'bg-yellow-500/20 text-yellow-300' : ''}
                            ${rarity === 'única' ? 'bg-red-500/20 text-red-300' : ''}
                          `}
                        >
                          <span className="mr-1 text-[10px] uppercase tracking-wider opacity-75">
                            {rarity}:
                          </span>
                          <span className="font-bold">
                            {count}
                          </span>
                        </div>
                      ))}
                    </div>

                    <div className="sm:hidden flex flex-col items-end ml-2 text-xs">
                      {Object.entries(
                        avatar.layers.reduce((acc, layer) => {
                          acc[layer.rarity] = (acc[layer.rarity] || 0) + 1;
                          return acc;
                        }, {})
                      ).map(([rarity, count]) => (
                        <div 
                          key={rarity}
                          className={`px-2 py-0.5 rounded-full mb-1 last:mb-0 flex items-center justify-center
                            ${rarity === 'común' ? 'bg-gray-500/20 text-gray-300' : ''}
                            ${rarity === 'raro' ? 'bg-blue-500/20 text-blue-300' : ''}
                            ${rarity === 'épico' ? 'bg-purple-500/20 text-purple-300' : ''}
                            ${rarity === 'legendario' ? 'bg-yellow-500/20 text-yellow-300' : ''}
                            ${rarity === 'única' ? 'bg-red-500/20 text-red-300' : ''}
                          `}
                        >
                          <span className="mr-1 text-[10px] uppercase tracking-wider opacity-75">
                            {rarity}:
                          </span>
                          <span className="font-bold">
                            {count}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RankingModal; 