import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { API_URL } from '../config';

const CategoryManager = ({ categories, setCategories, onDragEnd }) => {
  const [newCategory, setNewCategory] = useState('');

  const getAuthHeaders = () => ({
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json'
  });

  const addCategory = async () => {
    if (newCategory && !categories.includes(newCategory)) {
      try {
        const newCategories = [...categories, newCategory];
        const response = await fetch(`${API_URL}/categories/order`, {
          method: 'POST',
          headers: getAuthHeaders(),
          body: JSON.stringify({ categories: newCategories })
        });

        if (response.ok) {
          setCategories(newCategories);
          setNewCategory('');
        }
      } catch (error) {
        console.error('Error al añadir categoría:', error);
      }
    } else if (categories.includes(newCategory)) {
      alert('Esta categoría ya existe');
    }
  };

  const deleteCategory = async (categoryToDelete) => {
    if (window.confirm(`¿Estás seguro de eliminar la categoría ${categoryToDelete}?`)) {
      try {
        const newCategories = categories.filter(category => category !== categoryToDelete);
        const response = await fetch(`${API_URL}/categories/order`, {
          method: 'POST',
          headers: getAuthHeaders(),
          body: JSON.stringify({ categories: newCategories })
        });

        if (response.ok) {
          setCategories(newCategories);
        }
      } catch (error) {
        console.error('Error al eliminar categoría:', error);
      }
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex gap-4">
        <input
          type="text"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          placeholder="Nueva categoría"
          className="flex-1 px-4 py-2 bg-gray-700 text-white rounded-md border border-gray-600 focus:outline-none focus:ring-2 focus:ring-purple-500"
        />
        <button 
          onClick={addCategory}
          className="px-6 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors duration-200"
        >
          Añadir
        </button>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-categories">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={`space-y-2 ${
                snapshot.isDraggingOver ? 'bg-gray-700/50' : ''
              } rounded-lg transition-colors duration-200`}
            >
              {categories.map((category, index) => (
                <Draggable
                  key={category}
                  draggableId={category}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`
                        flex items-center justify-between
                        px-4 py-3 bg-gray-700 rounded-md
                        ${snapshot.isDragging ? 'ring-2 ring-purple-500 shadow-lg' : ''}
                        transition-all duration-200
                      `}
                    >
                      <div className="flex items-center gap-3">
                        <span className="text-gray-400">☰</span>
                        <span className="text-white">{category}</span>
                      </div>
                      <button
                        onClick={() => deleteCategory(category)}
                        className="p-1 hover:bg-gray-600 rounded-full transition-colors duration-200"
                      >
                        <svg 
                          className="w-5 h-5 text-gray-400 hover:text-red-400" 
                          fill="none" 
                          stroke="currentColor" 
                          viewBox="0 0 24 24"
                        >
                          <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M6 18L18 6M6 6l12 12" 
                          />
                        </svg>
                      </button>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default CategoryManager;