import React from 'react';
import PropTypes from 'prop-types';

const AvatarPreview = React.memo(({ layers }) => {
  return (
    <div className="relative w-full pt-[100%] bg-gray-700 rounded-lg overflow-hidden">
      <div className="absolute inset-0 flex items-center justify-center">
        {layers
          .sort((a, b) => a.zIndex - b.zIndex)
          .map(layer => (
            <img
              key={layer._id}
              src={layer.imageUrl}
              alt={layer.name}
              className="absolute inset-0 w-full h-full object-contain"
              style={{ zIndex: layer.zIndex }}
            />
          ))}
      </div>
    </div>
  );
});

AvatarPreview.propTypes = {
  layers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      zIndex: PropTypes.number.isRequired
    })
  ).isRequired
};

export default AvatarPreview; 