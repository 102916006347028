import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './components/HomePage';
import AdminPanel from './components/AdminPanel';
import UserAvatarView from './components/UserAvatarView';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import RankingPage from './components/RankingPage';
import GenerateRandomAvatar from './components/GenerateRandomAvatar';
import ImageOnly from './components/ImageOnly';
import OverlayPage from './pages/OverlayPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route 
          path="/admin" 
          element={
            <PrivateRoute>
              <AdminPanel />
            </PrivateRoute>
          } 
        />
        <Route path="/misrraelita/:username" element={<UserAvatarView />} />
        <Route path="/ranking" element={<RankingPage />} />
        <Route 
          path="/admin/generarrandom/:username" 
          element={
            <PrivateRoute>
              <GenerateRandomAvatar />
            </PrivateRoute>
          } 
        />
        <Route path="/misrraelita/:username/image" element={<ImageOnly />} />
        <Route path="/overlay/activity" element={<OverlayPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;