import React from 'react';
import RecentActivityOverlay from '../components/RecentActivityOverlay';

const OverlayPage = () => {
  return (
    <div className="h-screen w-screen overflow-hidden">
      <RecentActivityOverlay />
    </div>
  );
};

export default OverlayPage; 