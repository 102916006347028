import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faCrown, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import PublicAvatarSearch from './PublicAvatarSearch';
import RankingModal from './RankingModal';
import InfoModal from './InfoModal';
import HallOfFameModal from './HallOfFameModal';

const HomePage = () => {
  const [isRankingModalOpen, setIsRankingModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isHallOfFameModalOpen, setIsHallOfFameModalOpen] = useState(false);

  return (
    <>
      {/* Fondo */}
      <div 
        className="fixed inset-0 -z-20"
        style={{ 
          backgroundImage: `url("/img/fondo.jpg")`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'black',
          filter: 'brightness(0.7) blur(1px)'
        }}
      />

      {/* Footer - ahora con z-index más bajo */}
      <footer 
        className="fixed bottom-0 left-0 right-0 h-[100px] z-[-10] pointer-events-none select-none"
        style={{
          background: 'url(/img/footer.png) no-repeat center center',
          backgroundSize: 'cover'
        }}
      />

      {/* Contenido principal - Ajustado para móviles */}
      <div className="min-h-screen relative z-10 flex items-center justify-center p-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-3xl mx-auto bg-black/75 backdrop-blur-sm rounded-2xl p-4 sm:p-8 shadow-2xl">
          {/* Logo y descripción */}
          <div className="text-center">
            <img 
              src="/img/logo.png" 
              alt="Logo" 
              className="mx-auto h-24 sm:h-32 shadow-lg rounded-lg transform hover:scale-105 transition-transform duration-300"
            />
            <p className="mt-4 sm:mt-6 text-lg sm:text-xl text-gray-300">
              "Streamer Fracasado xd"
            </p>
          </div>

          {/* Grid de botones principales - Responsive */}
          <div className="mt-6 space-y-4">
            {/* Primera fila - Grid responsive */}
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-3 sm:gap-4">
              <a 
                href="https://twitch.tv/misrravb"
                className="flex items-center justify-center px-4 sm:px-8 py-4 sm:py-5 rounded-xl bg-black/50 hover:bg-purple-900/30 border border-purple-500/30 transform hover:-translate-y-1 transition-all duration-200 shadow-lg group"
              >
                <img src="/img/twitch.jpg" alt="Twitch" className="h-10 w-10 sm:h-12 sm:w-12 rounded-lg mr-3 sm:mr-4 group-hover:scale-105 transition-transform duration-200" />
                <span className="text-base sm:text-lg font-medium text-purple-100">TWITCH</span>
              </a>
              
              <a 
                href="https://youtube.com/@MisrraVB"
                className="flex items-center justify-center px-4 sm:px-8 py-4 sm:py-5 rounded-xl bg-black/50 hover:bg-red-900/30 border border-red-500/30 transform hover:-translate-y-1 transition-all duration-200 shadow-lg group"
              >
                <img src="/img/youtube.jpg" alt="YouTube" className="h-10 w-10 sm:h-12 sm:w-12 rounded-lg mr-3 sm:mr-4 group-hover:scale-105 transition-transform duration-200" />
                <span className="text-base sm:text-lg font-medium text-red-100">Momentos Stream</span>
              </a>

              <a 
                href="https://youtube.com/@MisrraVBLoL"
                className="flex items-center justify-center px-4 sm:px-8 py-4 sm:py-5 rounded-xl bg-black/50 hover:bg-red-900/30 border border-red-500/30 transform hover:-translate-y-1 transition-all duration-200 shadow-lg group"
              >
                <img src="/img/youtube2.jpg" alt="YouTube" className="h-10 w-10 sm:h-12 sm:w-12 rounded-lg mr-3 sm:mr-4 group-hover:scale-105 transition-transform duration-200" />
                <span className="text-base sm:text-lg font-medium text-red-100 text-center">LoL Gameplays</span>
              </a>
            </div>

            {/* Segunda fila - Grid responsive */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4 max-w-full sm:max-w-[66%] mx-auto">
              <a 
                href="https://youtube.com/channel/UCGGXQNtwySzgQuij57vAIcQ"
                className="flex items-center justify-center px-4 sm:px-8 py-4 sm:py-5 rounded-xl bg-black/50 hover:bg-red-900/30 border border-red-500/30 transform hover:-translate-y-1 transition-all duration-200 shadow-lg group"
              >
                <img src="/img/youtube3.jpg" alt="YouTube" className="h-10 w-10 sm:h-12 sm:w-12 rounded-lg mr-3 sm:mr-4 group-hover:scale-105 transition-transform duration-200" />
                <span className="text-base sm:text-lg font-medium text-red-100">Misrra Prime</span>
              </a>
              
              <a 
                href="https://kick.com/misrravb"
                className="flex items-center justify-center px-4 sm:px-8 py-4 sm:py-5 rounded-xl bg-black/50 hover:bg-green-900/30 border border-green-500/30 transform hover:-translate-y-1 transition-all duration-200 shadow-lg group"
              >
                <img src="/img/kick.jpg" alt="Kick" className="h-10 w-10 sm:h-12 sm:w-12 rounded-lg mr-3 sm:mr-4 group-hover:scale-105 transition-transform duration-200" />
                <span className="text-base sm:text-lg font-medium text-green-100">KICK</span>
              </a>
            </div>
          </div>

          {/* Redes sociales - Responsive */}
          <div className="mt-6 sm:mt-8 flex justify-center space-x-6 sm:space-x-8">
            <a 
              href="https://facebook.com/misrravb" 
              className="text-gray-400 hover:text-blue-500 transition-colors duration-200 transform hover:scale-110"
            >
              <FontAwesomeIcon icon={faFacebook} className="h-6 w-6 sm:h-8 sm:w-8" />
            </a>
            <a 
              href="https://twitter.com/misrravt" 
              className="text-gray-400 hover:text-blue-400 transition-colors duration-200 transform hover:scale-110"
            >
              <FontAwesomeIcon icon={faTwitter} className="h-6 w-6 sm:h-8 sm:w-8" />
            </a>
            <a 
              href="https://instagram.com/misrravb" 
              className="text-gray-400 hover:text-pink-500 transition-colors duration-200 transform hover:scale-110"
            >
              <FontAwesomeIcon icon={faInstagram} className="h-6 w-6 sm:h-8 sm:w-8" />
            </a>
          </div>

          <div className="mt-8 sm:mt-12 border-t border-gray-600"></div>

          {/* Búsqueda y botones inferiores - Responsive */}
          <div className="mt-8 sm:mt-12">
            <PublicAvatarSearch />
            <div className="mt-6 grid grid-cols-1 sm:grid-cols-3 gap-3 sm:gap-4">
              <button
                onClick={() => setIsInfoModalOpen(true)}
                className="inline-flex items-center justify-center px-4 sm:px-6 py-3 border border-transparent text-sm sm:text-base font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200 transform hover:-translate-y-1"
              >
                <FontAwesomeIcon icon={faUsers} className="mr-2" />
                MISRRAELITAS
              </button>

              <button
                onClick={() => setIsRankingModalOpen(true)}
                className="inline-flex items-center justify-center px-4 sm:px-6 py-3 border border-transparent text-sm sm:text-base font-medium rounded-lg text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 transition-all duration-200 transform hover:-translate-y-1"
              >
                <FontAwesomeIcon icon={faTrophy} className="mr-2" />
                VER RANKING
              </button>

              <button
                onClick={() => setIsHallOfFameModalOpen(true)}
                className="inline-flex items-center justify-center px-4 sm:px-6 py-3 border border-transparent text-sm sm:text-base font-medium rounded-lg text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-all duration-200 transform hover:-translate-y-1"
              >
                <FontAwesomeIcon icon={faCrown} className="mr-2" />
                HALL DE LA FAMA
              </button>
            </div>
          </div>
        </div>
      </div>

      <RankingModal 
        isOpen={isRankingModalOpen} 
        onClose={() => setIsRankingModalOpen(false)} 
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
      />
      <HallOfFameModal
        isOpen={isHallOfFameModalOpen}
        onClose={() => setIsHallOfFameModalOpen(false)}
      />
    </>
  );
};

export default HomePage;