import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API_URL } from '../config';

const ImageOnly = () => {
  const { username } = useParams();

  useEffect(() => {
    window.location.href = `${API_URL}/avatars/${username}/image`;
  }, [username]);

  return null;
};

export default ImageOnly; 